body{
    background-color: #f6f7fb;
    font-size: 0.875em;
    overflow-x: hidden;
    color: #353c4e;
    font-family: open sans,sans-serif;
    background-attachment: fixed;

}
.login-block {
    padding: 30px 0;
    margin: 0 auto;
    background-size: cover;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.login-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    border: none;
    margin-bottom: 30px;
}

.card-block {
    padding: 1.25rem;
}

.form-group {
    margin-bottom: 1.25em;
}
.form-control {
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid #ccc;
}

.btn-md {
    padding: 10px 16px;
    font-size: 15px;
    line-height: 23px;
}
.btn-primary, .sweet-alert button.confirm, .wizard>.actions a {
    background-color: #fe9365;
    border-color: #fe9365;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in .3s;
    transition: all ease-in .3s;
}

.btn-primary:hover, .sweet-alert button.confirm:hover, .wizard>.actions a:hover{
    background-color: #feb798;
    border-color: #feb798;
}

.btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 7px 11px;
    cursor: pointer;
}
.text-center {
    text-align: center;
}
.m-b-20 {
    margin-bottom: 20px;
}

ul{list-style: none;}

.none{display: none;}


/* General */
html{height: 100%;}
html, body, #root{min-height: 100%;}
.bg-menu-close{width: 100%; height: 100vh; position: absolute; top: 0;left: 0;z-index: 0;}
.error{color:red}
.border-error{border-color: red;}
.container-dashboard{ min-height: calc(100vh - 56px);}
a{color: #404e67; font-size: 14px; }
a:hover{color: #fe8a7d!important; text-decoration: none;}
.fleft{float: left;}
.fright{float: right;}
.clearer{clear: both;}


/* Header */
.header{height: 56px; width: 100%;flex-wrap: wrap; -webkit-box-shadow: 0 0 11px rgb(0 0 0 / 13%); box-shadow: 0 0 11px rgb(0 0 0 / 13%); position: fixed; z-index: 100;background: #fff;}
.header .logo{ background-color: #404e67; width: 240px; height: 100%; padding: 0px; color: white;position: relative; float: left; line-height: 56px; padding-left: 20px;}
.header .nav-container .nav-left{float: left;margin-bottom: 0;}
.header .nav-container .nav-right{float: right;margin-bottom: 0;margin-right: 10px;;}
.header .nav-container .nav-right li{line-height: 3.5; cursor: pointer;}
.header .nav-container .nav-right li:hover > span.user-info{color:#fe8a7d;transition: color .4s;}

.header .nav-container .nav-right li.user-profile .user-menu{
    background: #fff;
    -webkit-box-shadow: 0 2px 30px 6px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 30px 6px rgb(0 0 0 / 10%);
    position: absolute;
    -webkit-transition: all .3s ease-in-out;
    transition: display .3s ease-in-out;
    
    right:20px;
    top: 75px;
    left: auto;
    overflow: visible;
    border: none;
    z-index: 1;
    padding: 0 00px;
}
.header .nav-container .nav-right li.user-profile .user-menu:after{
    content: "";
    position: absolute;
    top: -10px;
    right: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 10px solid #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-box-shadow: -10px -9px 23px 1px rgb(0 0 0 / 10%);
    box-shadow: -10px -9px 23px 1px rgb(0 0 0 / 10%);
}
.header .nav-container .nav-right li.user-profile .user-menu li{padding: 5px 52px;}
.header .nav-container .nav-right li.user-profile .user-menu li:hover{background-color: #feb798;}

/**    NAVBAR    */
.navbar{height: 100%; width: 240px;; background-color:  #404e67; float: left;position: fixed; padding-top: 56px;align-items: initial; display: block;}
.navbar .title {color: #999!important; font-size: 14px; font-weight: 400; letter-spacing: 1px; opacity: 1; padding: 25px 20px 8px 0; text-transform: capitalize; visibility: visible; width: 100%;  -webkit-transform: rotateX(0deg); -ms-transform: rotateX(0deg); -moz-transform: rotateX(0deg); -o-transform: rotateX(0deg); transition: opacity .3s linear; -webkit-transition: opacity .3s linear; -ms-transition: opacity .3s linear; -moz-transition: opacity .3s linear; -o-transition: opacity .3s linear; }
.navbar .link{display:block;width: 100%; color: #dcdcdc;font-weight: 850; padding: 8px 15px;}

/** COntent page **/
.content-page { height: 100%; padding-top: 56px; padding-left: 240px; padding: 86px 10px 0 280px;}

/** Modal **/
.bg-modal{background-color: black; opacity: .7;width: 100%; height: 100%; position: fixed;}
.modal{overflow: scroll;}

/** Captcha **/
.reloadCaptcha{color: #fe8a7d!important; cursor: pointer;}
.reloadCaptcha:hover{text-decoration: underline;}

/** Spinner **/
.bg-loader{background-color: #f6f7fb; opacity: .7;width: 100%; height: 100%; position: fixed; z-index: 99;}
@keyframes spinner-border { to { transform: rotate(360deg); } }
.spinner-border { display: inline-block; width: 1.2rem;  height: 1.2rem;  vertical-align: text-bottom;  border: 0.25em solid currentColor;  border-right-color: transparent;  border-radius: 50%;  animation: spinner-border .75s linear infinite;}
.sr-only { position: absolute; width: 1px;  height: 1px; padding: 0; overflow: hidden; clip: rect(0,0,0,0);  white-space: nowrap; border: 0;}

.lds-dual-ring {display: inline-block; width: 80px; height: 80px;}
.lds-dual-ring:after { content: " "; display: block; width: 64px; height: 64px; margin: 8px; border-radius: 50%; border: 6px solid #fff; border-color: #fbb498 transparent #fbb498 transparent; animation: lds-dual-ring 1.2s linear infinite;}
@keyframes lds-dual-ring { 0% {transform: rotate(0deg); } 100% { transform: rotate(360deg);} }

/** Table **/
table { border: solid 1px #ffd9ca;}
table thead th{background-color: #ffd9ca;border-right: 1px solid white; padding: 0px 20px;}
table tbody tr:nth-child(even){background-color:#ddd;}
table tbody tr td{padding: 10px 20px}
table tbody tr:hover{background-color: #cdcdcd;}

table tbody  tr.tr-loader:hover {background-color: transparent;}
table tbody  .td-loader{padding: 100px;;}

/** Top Bar  AND Bottom Bar Datatable**/
.top-bar, .bottom-bar{ padding: 10px 0; text-align:right}
.top-bar .input-search{ width: 250px;}
.top-bar input, select{border: 1px solid #ccc; padding: 5px;}
.bottom-bar .pagination .page-item.disabled .page-link{ background-color: #ffd9ca;}

/** Button **/
button{border-radius: 2px; border-style: initial; cursor: pointer;}
button:hover{opacity: 0.7;}
button.action{background-color: #fe9365; border-color: #fe9365; color:white; padding: 5px; border-radius: 2px;}
button.ticket-action{width: 100px; height: 100px; padding: 20px;;}

/** Ticket **/
.ticket .comment{background-color: #eee; border: 1px solid #ddd; padding: 5px;}


/** Serial **/

.block-serial-printed{width: 200px; float: left; background-color: #ddd; margin: 10px; padding: 10px;}
.block-serial-printed .printedAt {display:block; font-size: 9px; color: #777;}
.block-serial-printed .serial {display:block; font-size:11px;}


/** Badges **/
.label{border-radius: 4px; font-size: 75%; padding: 4px 7px; margin-right: 5px; font-weight: 400; color: #fff !important;}
.label-created{ background: linear-gradient(to right, #2DCEE3, #5ad9e9);}
.label-onProgress{ background: linear-gradient(to right, #fe9365, #feb798);}
.label-closed{ background: linear-gradient(to right, #404E67, #546686);}